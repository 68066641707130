.container {
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0 rgba(0, 0, 0, 0.02);
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    background-color: #f2f2f2;
  }

  &:focus {
    outline: none;
  }
}
