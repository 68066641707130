.container {
  width: 100%;

  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);

  position: relative;

  .map-action {
    margin-right: 7px;
    margin-bottom: 50px;

    .compass {
      margin-bottom: 10px;
    }
  }

  .word-tour {
    pointer-events: none;
  }

  .map-top {
    display: flex;
    flex: row;
    align-items: flex-end;
    .map-editor {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .icon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 20px;
      }
    }
    .geocoding {
      width: 100vw;
    }
  }
}
