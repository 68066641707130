.container {
  position: absolute;
  display: flex;
  flex-direction: column;

  &.top-left {
    top: 0;
    left: 0;
    align-items: flex-start;
  }

  &.top-right {
    top: 0;
    right: 0;
    align-items: flex-end;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
    align-items: flex-end;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
    align-items: flex-start;
  }
}
