.container {
    pointer-events: none;
    padding: 0 50px 45px 10px;
    width: 100vw;

    .start-button {
        pointer-events: auto;
        border: none;
        padding: 10px;
        cursor: pointer;
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0 rgba(0, 0, 0, 0.02);

        &:focus {
            outline: none;
        }

        &::-moz-focus-inner {
            border: 0;
        }

        &:hover {
            background-color: #f2f2f2;
        }

        & > span {
            margin-left: 10px;
        }
    }

    .scrollbars {
        width: 100%;
        pointer-events: auto;

        & > div {
            min-height: 0 !important;
            overflow: hidden !important;
            margin: 0 !important;
        }
    }

    .list {
        display: flex;
        max-height: 0;
        transition: max-height .3s;

        &.show {
            max-height: 150px;
        }

        .place {
            flex-shrink: 0;
            cursor: pointer;
            width: 125px;
            height: 125px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            background-color: #ffffff;
            border: 4px solid #ffffff;
            opacity: 0.5;
            color: #2999fd;
            transition: opacity .3s, border-color .3s, background-color .3s;
            margin: 10px 0 15px 0;

            &.selected, &:hover {
                opacity: 1;
                border-color: #2999fd;
            }

            &:hover:not(.selected) {
                background-color: #ffffff80;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }

            .name, .country {
                text-align: center;
                font-size: 1rem;
            }
        }
    }
}
