.container {
  width: 100vw;
  height: 100vh;
  position: relative;

  .map-action {
    margin-right: 10px;
    margin-bottom: 25px;

    .compass {
      margin-bottom: 10px;
    }
  }

  .word-tour {
    pointer-events: none;
  }
}
