* {
    box-sizing: border-box;
}

html {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}
